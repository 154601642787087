import gql from 'graphql-tag'


const inventoryConfigResponse = `
  id type inventoryType name regexExp isDefault
  options {isParent}
  paperId paper {id type code name}
`

export const LIST_CONFIG_MENU = (templateType) => gql`query LIST_CONFIG_MENU ($inventoryStockType: String!) {
  menus: list${templateType}ConfigMenu (inventoryStockType: $inventoryStockType)
}`

export const LIST_CONFIG = (templateType) => gql`query LIST_CONFIG ($inventoryStockType: String!, $q: FilterInput) {
  items: list${templateType}Config (inventoryStockType: $inventoryStockType, q: $q) {${inventoryConfigResponse}}
}`

export const CREATE_CONFIG = (templateType) => gql`mutation CREATE_CONFIG ($inventoryStockType: String!, $inventoryConfigId: Int, $input: InventoryConfigInput!) {
  item: create${templateType}Config (inventoryStockType: $inventoryStockType, inventoryConfigId: $inventoryConfigId, input: $input) {${inventoryConfigResponse}}
}`

export const DESTROY_CONFIG = (templateType) => gql`mutation DESTROY_CONFIG ($inventoryStockType: String!, $inventoryConfigId: Int!) {
  item: destroy${templateType}Config (inventoryStockType: $inventoryStockType, inventoryConfigId: $inventoryConfigId) {id}
}`

export const DP_PAPERS = (templateType) => gql`query DP_PAPERS ($inventoryStockType: String!, $q: FilterInput) {
  items: list${templateType}ConfigDropdownPaper (inventoryStockType: $inventoryStockType, q: $q) {
    id type code name
  }
}`
